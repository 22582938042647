import React, { useState, useEffect } from 'react';
import qs from 'qs';

import { Alert } from '../../components/Alert'
import { Button, HollowButton, NakedButton } from '../../components/Button';
import { CardContainer, ColContainer, Container } from '../../components/Container';
import { DateInput } from '../../components/DateInput';
import { Byline, H1, H2, HR } from '../../components/Typography';
import { Input } from '../../components/Input';
import { Loading, LoadingError } from '../../components/LoadingFork';
import { SelectInput } from '../../components/SelectInput';
import { StripeElementsInput } from '../../components/StripeElementsInput';
import { GraphqlErrorMessage, ValidationErrorMessage } from '../../components/ErrorMessage';
import { useFormState } from 'react-use-form-state'
import { useMutation, useQuery } from "@apollo/client";
import { withAppConfig } from '../../components/AppConfigContext';
import { withInstallation } from '../../components/InstallationContext';
import ReactModal from 'react-modal'
import { format as formatDate, isSameDay, lightFormat } from 'date-fns'
import { details, determineDays, requirements, } from '../../lib/rateCardUtils';
import { ParkingSummary, ParkingSummaryHeader } from './components/ParkingSummary';
import drivoApollo, { DRIVO_URL } from '../../lib/drivoApollo';
import gql from 'graphql-tag'

import { allActiveRateCards, bookingById, eventById, getStripePaymentMethods } from './queries'
import { createBookingMutation, updateBookingMutation } from './mutations'
import { css } from "glamor";
import * as enums from '../../lib/enums'
import { formatPlate } from "../../lib/misc";
import { DateTime } from "luxon";
import { TextArea } from '../../components/TextArea';
import { orange, red } from '../../theme';

function ArchivedRateCard(rateCard) {
    return <Alert flavour='danger' spaceAbove='medium'>
        <strong>Guest card no-longer available</strong>
        <p>
            The guest card used <strong>{rateCard.name}</strong> is archived.
            You will need to select a new guest card to continue.
        </p>
    </Alert>
}

function RateCardOptions({ rateCards, onChange, value }) {

    function _onChange(e) {
        const rateCard = rateCards.find(x => x.id === e.target.value)
        onChange(rateCard)
    }

    // remove duplicates by id
    const cardsMap = {}
    const lineageCounts = {}
    rateCards.forEach((card) => {
        cardsMap[card.id] = card
    })

    // count if something is repeated
    for (const id in cardsMap) {
        const { lineageId } = cardsMap[id]
        lineageCounts[lineageId] = (lineageCounts[lineageId] || 0) + 1
    }

    const options = []
    for (const id in cardsMap) {
        const {
            name,
            lineageId,
            isArchived,
            version
        } = cardsMap[id]

        // differentiate rateCards of same lineage by version
        let label = `${name}`
        if (lineageCounts[lineageId] > 1) {
            label = `${label} (version ${version})`
        } else if (isArchived) {
            label = `${label} (archived)`
        }

        options.push({
            label,
            value: id,
            disabled: isArchived
        })
    }

    // sort by label
    options.sort((a, b) => a.label.localeCompare(b.label))

    return <SelectInput
        label='Guest Card'
        placeholder='Select a guest card'
        autoComplete='off'
        options={options}
        type='select'
        onChange={_onChange}
        value={value ? value.id : undefined}
    />
}

function BookingHeader({ title, startedAt, children, installationId }) {
    const { data: capacity, loading: capacityLoading, refetch: capacityRefetch } = useQuery(gql`
        query Capacity($installationId:ID!)
        {
            getCapacity(installationId:$installationId){
                id
                capacity
                limitToSanctionedPlatesEnabled
                limitToSanctionedPlateCapacity
                bookingsUntilFull
            }
        }
    `, {
        client: drivoApollo,
        variables: { installationId: installationId },
        fetchPolicy: "no-cache"
    });

    const vacantSpots = isNaN(capacity?.getCapacity?.bookingsUntilFull) || capacity?.getCapacity?.bookingsUntilFull <= 0 ? 0 : capacity?.getCapacity?.bookingsUntilFull;

    const checkIfAlmostFull = (parkCapacity) => {
        if (!parkCapacity || parkCapacity <= 0 || isNaN(vacantSpots)) return false;

        const parkedSlots = parkCapacity - vacantSpots;
        const lotFilledRate = parkedSlots / parkCapacity;

        if (vacantSpots === 0 || lotFilledRate >= 0.95) return true;

        return false;
    }

    const almostFull = checkIfAlmostFull(capacity?.getCapacity?.limitToSanctionedPlateCapacity, vacantSpots);

    const dateStr = startedAt ? formatDate(new Date(startedAt), 'h:mm a, EEEE d MMM yyyy') : null;

    return (
        <Container size='large' spaceBelow='medium'>
            <H1 spaceBelow='none'>{title}</H1>
            {almostFull &&
                <H2
                    style={{
                        color: vacantSpots === 0 ? red : orange
                    }}
                >
                    {vacantSpots === 0 ? "Car park is currently full!" : `Remaining vacant spots: ${vacantSpots}`}
                </H2>
            }
            <Byline>Starts at: {!startedAt ? "-" : dateStr}</Byline>
            <HR />
            {children}
        </Container>
    )
}

function isToday(date, timezone) {
    if (!date) return false;

    return date.hasSame?.(DateTime.now().setZone(timezone), "day")
}

const modalStyles = css({
    display: 'block',
    background: 'white',
    margin: 10,
    padding: 20,
    borderRadius: 6,
    outline: 'none',
    minWidth: 300,
    maxWidth: 500,
}).toString();

const modalOverlayStyles = css({
    backgroundColor: 'rgba(0,0,0,0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 100000,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
}).toString();

const BookingForm = withAppConfig(function BookingForm({
    defaultPlate,
    eventId,
    installation,
    startedAt,
    booking,
    rateCards,
    appConfig,
    onSubmit,
    onCancel
}) {
    if (booking) {
        rateCards = [...rateCards, booking.rateCard]
    }

    const [{
        errors,
        values,
        setField,
    }, { text, email, tel, raw, select }] = useFormState({
        rateCard: booking ? booking.rateCard : null,
        guestPlate: defaultPlate,

        // Daily fields
        startDay: booking ? DateTime.fromMillis(Number(booking.startDay), { locale: "en-AU" }).setZone(installation.timeZoneName).toFormat('yyyy-MM-dd') : lightFormat(new Date(), 'yyyy-MM-dd'),
        endDay: booking ? DateTime.fromMillis(Number(booking.endDay), { locale: "en-AU" }).setZone(installation.timeZoneName).toFormat('yyyy-MM-dd') : lightFormat(new Date(), 'yyyy-MM-dd'),
        staffName: booking ? booking.staffName : '',
        // Guest fields
        guestName: booking ? booking.guestName : '',
        guestEmail: booking ? booking.guestEmail : '',
        guestPhone: booking ? booking.guestPhone : '',
        guestRoom: booking ? booking.guestRoom : '',
        guestReservation: booking ? booking.guestReservation : '',
        stripeSource: booking?.stripeSource || null,
        stripeCustomerId: booking?.stripeCustomerId || null,
        status: booking ? booking.status : undefined,
        carType: booking ? booking.carType : '',
        carNotes: booking ? booking.carNotes : '',
    })
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isStaffModalOpen, setStaffModalOpen] = useState(false)

    const [createBooking, { error: createError }] = useMutation(createBookingMutation)
    const [updateBooking, { error: updateError }] = useMutation(updateBookingMutation)

    const { startDay, endDay, rateCard } = values
    const [paymentMethod, setPaymentMethod] = useState('')
    const required = rateCard ? requirements(rateCard) : {}

    const hasErrors = Object.values(errors).some(x => x)
    const [submitError, setSubmitError] = useState(createError || updateError);
    const canSubmit =
        !isSubmitting &&
        !hasErrors && rateCard &&
        (!required.guestName || values.guestName) &&
        (!required.guestEmail || values.guestEmail) &&
        (!required.guestPhone || values.guestPhone) &&
        (!required.includeCarType || values.carType) &&
        (!required.includeCarNotes || values.carNotes) &&
        (!required.guestReservation || values.guestReservation) &&
        (!required.guestRoom || values.guestRoom) &&
        (values.staffName || !isModalOpen) &&
        (!required.endDay || endDay) &&
        !rateCard.isArchived

    const fetchPaymentMethod = async (id) => {
        if (!id) return;
        try {
            const paymentMethod = await drivoApollo.query({
                query: getStripePaymentMethods,
                variables: {
                    customerId: id,
                },
            });
            setPaymentMethod(paymentMethod.data.getStripePaymentMethods[0]);
        } catch (error) {
            console.error("Error fetching payment method:", error);
        }
    };

    useEffect(() => {
        fetchPaymentMethod(booking?.stripeCustomerId);
    }, []);

    useEffect(() => {
        fetchPaymentMethod(values.stripeCustomerId);
    }, [values.stripeCustomerId]);

    function validateStartDay(startDay, { rateCard, endDay }) {
        if (!startDay || !endDay || !rateCard) return
        try {
            const days = determineDays(startDay, endDay, rateCard, installation.timeZoneName)
            if (rateCard.calculationMode === "SINGLE_DAY" && days > 1) {
                throw new Error("Single day rate card cannot be used for multi-day booking.")
            }
        } catch (e) {
            return `Invalid booking start day (${e.message})`
        }

    }

    function validateEndDay(endDay, { rateCard, startDay }) {
        if (!startDay || !endDay || !rateCard) return
        try {
            const days = determineDays(startDay, endDay, rateCard, installation.timeZoneName)
            if (rateCard.calculationMode === "SINGLE_DAY" && days > 1) {
                throw new Error("Single day rate card cannot be used for multi-day booking.")
            }
        } catch (e) {
            return `Invalid booking end day (${e.message})`
        }
    }

    async function _onSubmit() {
        if (required.paymentStripe && !paymentMethod) {
            setSubmitError({ paymentStripe: true, message: 'Stripe Rate Card requires confirmed payment method.' });
            return;
        }

        setIsSubmitting(true);
        console.log({
            startDay,
            fromISO: DateTime.fromISO(startDay, { zone: installation.timeZoneName }),
            startOfDay: DateTime.fromISO(startDay, { zone: installation.timeZoneName }).startOf('day'),
            toISO: DateTime.fromISO(startDay, { zone: installation.timeZoneName }).startOf('day').toISODate(),
        })

        let startDateTime = required.startDay ? DateTime.fromISO(startDay, { zone: installation.timeZoneName }).startOf('day').toISO() : null;
        let endDateTime = required.endDay ? DateTime.fromISO(endDay, { zone: installation.timeZoneName }).startOf('day').toISO() : null;

        // Calculate full timestamps for DAILY bookings
        if (required.startDay && required.endDay && rateCard?.type === 'DAILY') {
            startDateTime = DateTime.fromISO(startDay, { zone: 'UTC' })
                .plus({
                    minutes: rateCard.entryAfterTime,
                })
                .setZone(installation.timeZoneName, { keepLocalTime: true })
                .toISO();

            endDateTime = DateTime.fromISO(endDay, { zone: 'UTC' })
                .plus({
                    minutes: rateCard.exitBeforeTime,
                    // days: startDay === endDay ? 1 : 0, // same-day daily booking adjustment
                })
                .setZone(installation.timeZoneName, { keepLocalTime: true })
                .toISO();
        }

        const sharedValues = {
            installationId: installation.id,
            rateCardId: rateCard ? rateCard.id : null,
            plate: formatPlate(values.guestPlate),

            // Daily fields
            startDay: startDateTime,
            endDay: endDateTime,

            // Guest fields
            staffName: values && values.staffName ? values.staffName : null,
            guestName: required.guestName ? values.guestName : '',
            guestPhone: required.guestPhone ? values.guestPhone : '',
            guestEmail: required.guestEmail ? values.guestEmail : '',
            guestRoom: required.guestRoom ? values.guestRoom : '',
            guestReservation: required.guestReservation ? values.guestReservation : '',
            stripeCustomerId: values.stripeCustomerId || null,
            status: values.status,
            carType: required.includeCarType ? values.carType : '',
            carNotes: required.includeCarNotes ? values.carNotes : '',
        }

        if (booking) {
            const { data } = await updateBooking({
                variables: {
                    bookingId: booking.id,
                    ...sharedValues
                }
            })

            onSubmit(data.updateBooking)
            setIsSubmitting(false);
        } else {
            const { data } = await createBooking({
                variables: {
                    installationId: installation.id,
                    entryEventId: eventId,
                    ...sharedValues
                }
            })

            onSubmit(data.createBooking)
            setIsSubmitting(false);
        }
    }


    return <form>
        <Container size='large'>
            {submitError && <GraphqlErrorMessage error={submitError} />}
            {rateCard ? <>
                {rateCard.isArchived && <ArchivedRateCard rateCard={rateCard} />}
            </> : null}
            {hasErrors && <ValidationErrorMessage />}
            <Input required
                label='Licence Plate'
                placeholder='AAA-123'
                autoComplete='off'
                format={(v) => formatPlate(v)}
                disabled={!!eventId}
                error={errors.guestPlate}
                {...{
                    ...text('guestPlate'),
                    onChange: (e) => {
                        setField('guestPlate', formatPlate(e.target.value));
                    }
                }}
            />

            <RateCardOptions
                rateCards={rateCards}
                error={errors.rateCard}
                {...raw({
                    name: 'rateCard',
                })}
            />

            {booking && booking.status &&
                <SelectInput
                    label='Status'
                    placeholder='Select a status'
                    options={enums.BOOKING_STATUS_OPTIONS_WITHOUT_FINALISED}
                    type='select'
                    disabled={booking.status === "FINALISED" || booking.status === "CLOSED"}
                    value={booking.status}
                    {...select("status")}
                />
            }
            <HR spaceAbove='medium' spaceBelow='medium' />

            {required.guestDetails ? <>
                <H2 spaceBelow='medium'>Customer details</H2>
                {required.guestName ? <Input required
                    label='Full name'
                    placeholder='John Smith'
                    autoComplete='off'
                    error={errors.guestName}
                    {...text('guestName')}
                /> : null}
                {required.guestEmail ? <Input required
                    label='Email address'
                    placeholder='name@email.com'
                    autoComplete='off'
                    error={errors.guestEmail}
                    {...email('guestEmail')}
                /> : null}
                {required.guestPhone ? <Input required
                    label='Contact number'
                    placeholder='0400 123 456'
                    autoComplete='off'
                    error={errors.guestPhone}
                    {...tel('guestPhone')}
                /> : null}
                {required.guestRoom ? <Input required
                    label='Room'
                    placeholder='Room 123'
                    autoComplete='off'
                    error={errors.guestRoom}
                    {...text('guestRoom')}
                /> : null}
                {required.guestReservation ? <Input required
                    label='Reservation'
                    placeholder='ABCD1234'
                    autoComplete='off'
                    error={errors.guestReservation}
                    {...text('guestReservation')}
                /> : null}
                {required.includeCarType ? <Input required
                    label='Car Type'
                    placeholder='Car Type'
                    autoComplete='off'
                    error={errors.carType}
                    {...text('carType')}
                /> : null}
                {required.includeCarNotes ? <TextArea required
                    label='Car Notes'
                    placeholder='Car Notes'
                    autoComplete='off'
                    error={errors.carNotes}
                    {...text('carNotes')}
                /> : null}
            </> : null}

            {required.endDay ? <>
                <DateInput floatPicker
                    label='Scheduled Entry'
                    placeholder='Enter entry date'
                    autoComplete='off'
                    displayFormat='ddd, D MMM YYYY'
                    isOutsideRange={x => false}
                    isDayHighlighted={isToday}
                    error={errors.startDay}
                    type='text'
                    {...raw({
                        name: 'startDay',
                        validate: validateStartDay
                    })}
                />

                <DateInput floatPicker
                    label='Scheduled Exit'
                    placeholder='Enter exit date'
                    autoComplete='off'
                    displayFormat='ddd, D MMM YYYY'
                    isOutsideRange={x => false}
                    isDayHighlighted={(x) => isToday(x, installation.timeZoneName)}
                    error={errors.endDay}
                    type='text'
                    {...raw({
                        name: 'endDay',
                        validate: validateEndDay
                    })}
                />
                <HR spaceAbove='medium' spaceBelow='medium' />

                <H2>Parking summary</H2>
                <ParkingSummaryHeader
                    installation={installation}
                    rateCard={rateCard}
                    startDay={startDay}
                    endDay={endDay}
                    errors={hasErrors}
                />
                {startDay && endDay ? <ParkingSummary
                    installation={installation}
                    rateCard={rateCard}
                    startDay={startDay}
                    endDay={endDay}
                    entryTime={eventId ? startedAt : null}
                    errors={hasErrors}
                /> : null}
                <HR spaceAbove='medium' spaceBelow='medium' />
            </> : null}

            {rateCard?.paymentStripe &&
                <>
                    <H2>Payment details</H2>
                    <StripeElementsInput
                        appConfig={appConfig}
                        currency={installation.currency}
                        paymentMethod={paymentMethod}
                        setStripeCustomerId={(id) => {
                            setField('stripeCustomerId', id);
                            setField('stripeSource', null);
                        }}
                        {...raw('stripeSource')}
                    />
                    <HR spaceAbove='medium' spaceBelow='medium' />
                </>
            }
        </Container>

        <Container size='medium' spaceBelow='medium'>
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={() => setModalOpen(false)}
                className={modalStyles}
                overlayClassName={modalOverlayStyles}
            >
                {hasErrors && <ValidationErrorMessage />}
                <h1 className={css({ textAlign: 'left' })}>{rateCard?.name}</h1>
                <p style={{ fontSize: 'larger' }}>
                    Are you sure this customer has a parking booking on the hotel PMS (Opera or other)?
                    Drivo <span style={{ fontWeight: 'bold' }}>does not</span> charge via Opera automatically.
                </p>
                <p style={{ fontSize: 'larger' }}>
                    We strongly recommend using the Hotel Guest card if there is no prior
                    booking in the PMS.
                </p>

                <Input
                    required
                    error={errors.staffName}
                    placeholder='staff Name'
                    autoComplete='off'
                    label="Staff Name"
                    {...text('staffName')}
                />

                <ColContainer>
                    <HollowButton disabled={!canSubmit} className={css({ marginTop: 30 })} onClick={_onSubmit}>
                        Save
                    </HollowButton>
                    <HollowButton className={css({ marginTop: 30 })} onClick={() => setModalOpen(false)}>
                        Cancel
                    </HollowButton>
                </ColContainer>
            </ReactModal>

            <ReactModal
                isOpen={isStaffModalOpen}
                onRequestClose={() => (setStaffModalOpen(false))}
                className={modalStyles}
                overlayClassName={modalOverlayStyles}
            >
                <p style={{ fontSize: 'larger', textAlign: 'center' }}>
                    Are you sure you want to edit this booking? This action will be notified to management.
                </p>
                <ColContainer>
                    <HollowButton className={css({ marginTop: 30 })} onClick={_onSubmit}>
                        Proceed
                    </HollowButton>
                    <HollowButton className={css({ marginTop: 30 })} onClick={() => setStaffModalOpen(false)}>
                        Cancel
                    </HollowButton>
                </ColContainer>
            </ReactModal>

            {submitError && <GraphqlErrorMessage error={submitError} />}
            <Button
                block flavour='primary'
                disabled={!canSubmit}
                onClick={() => {
                    const rateCardName = rateCard?.name?.toLowerCase();
                    const publicUrl = rateCard?.publicUrl?.toLowerCase();

                    if (rateCardName.match('chargeback|free of charge|opera') != null) setModalOpen(true)
                    else if (rateCard && (rateCardName.includes('staff') || (publicUrl && publicUrl.includes('staff')))) setStaffModalOpen(true);
                    else _onSubmit();
                }}
            >
                {booking ? 'Update booking' : 'Create booking'}
            </Button>
            <NakedButton block flavour='gray' onClick={onCancel}>
                Cancel
            </NakedButton>
        </Container>
    </form>
})

function BookingCreate({
    installation,
    onCancel,
    onSubmit,
    plate,
    rateCards,
    eventId
}) {
    const { data = {}, loading, error } = useQuery(eventById, {
        skip: !eventId,
        variables: {
            id: eventId
        }
    });

    const { Event: event } = data
    const defaultPlate = plate || (event && event.plate) || ''
    const startedAt = event ? DateTime.fromISO(event.pcLoggedAt, { locale: "en-AU" }).setZone(installation.timeZoneName) : DateTime.now().setZone(installation.timeZoneName)

    return (
        <div>
            <BookingHeader title='New Booking' startedAt={startedAt?.toJSDate?.()} installationId={installation.id} />
            {error ? <LoadingError error={error} /> : null}
            {loading && <Loading />}
            {!loading && <BookingForm
                eventId={event ? event.id : null}
                defaultPlate={defaultPlate}
                installation={installation}
                rateCards={rateCards}
                startedAt={startedAt}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />}
        </div>
    )
}

function BookingEdit({
    installation,
    bookingId,
    onCancel,
    onSubmit,
    rateCards
}) {
    const [booking, setBooking] = useState(null)
    const [bookingDetails, setBookingDetails] = useState(null)
    const {
        data = {},
        loading,
        error
    } = useQuery(bookingById, {
        fetchPolicy: 'no-cache',
        variables: { bookingId }
    })

    useEffect(() => {
        if (!data) return;

        setBooking(data.Booking)
    }, [data])

    useEffect(() => {
        if (!booking) return;

        const fetchData = async () => {
            try {
                const res = await details(booking);
                setBookingDetails(res);
            } catch (e) {
                console.error(e.message)
            }
        };

        fetchData();
    }, [booking])

    if (error) return <LoadingError error={error} />
    if (loading) return <Loading />

    if (!booking) {
        return <Container size='large' spaceBelow='medium'>
            <H1>Not found</H1>
        </Container>
    }

    return <div>
        <BookingHeader title={booking?.plate} startedAt={bookingDetails?.startedAt?.toJSDate?.()} installationId={installation.id} />
        <BookingForm
            eventId={booking.initialVisit ? booking.initialVisit.id : null}
            installation={installation}
            booking={booking}
            defaultPlate={booking?.plate}
            rateCards={rateCards}
            startedAt={bookingDetails?.startedAt}
            onCancel={onCancel}
            onSubmit={onSubmit}
        />
    </div>
}

export default withInstallation(function BookingCreateEdit({
    match,
    location,
    history,
    installation,
}) {
    const rateQ = useQuery(allActiveRateCards, {
        fetchPolicy: 'no-cache',
        variables: {
            installationId: installation.id
        }
    })

    if (rateQ.loading) return (
        <CardContainer>
            <Loading />
        </CardContainer>
    )
    if (rateQ.error) return (
        <CardContainer>
            <LoadingError error={rateQ.error} />
        </CardContainer>
    )
    const rateCards = rateQ.data.allRateCards
    if (!rateCards.length) return (

        <CardContainer>
            <LoadingError error='Missing guest cards' />
        </CardContainer>

    )
    const {
        bookingId,
        eventId,
        plate
    } = qs.parse(location.search, { ignoreQueryPrefix: true });

    function onSubmit({ id, guestName }) {
        history.push(match.url.replace('/create', `/${id}`), {
            flash: {
                flavour: 'success',
                message: `Booking for ${guestName} created successfully`,
                createdAt: Date.now(),
            },
        });
    }

    function onCancel() {
        history.goBack()
    }

    if (bookingId) {
        return (
            <CardContainer>
                <BookingEdit
                    installation={installation}
                    bookingId={bookingId}
                    rateCards={rateCards}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            </CardContainer>

        )
    }
    return (
        < CardContainer>
            < BookingCreate
                installation={installation}
                plate={plate}
                rateCards={rateCards}
                eventId={eventId}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
        </CardContainer>


    )

})
