import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { css } from 'glamor'
import ReactModal from 'react-modal'

import * as theme from '../theme'
import {A, HR} from './Typography'
import { Button } from './Button'
import { ColContainer } from './Container'
import {inputSize} from "./Input";

function last4FromStripeSource (stripeSource) {
	try {
		const card = typeof stripeSource === 'string' ? JSON.parse(stripeSource)?.card : stripeSource?.card;
		return `•••• •••• •••• ${card?.last4}`
	} catch (err) {
		return 'Cannot display card number.'
	}
}

function expiryFromStripeSource (stripeSource) {
	try {
		const card = typeof stripeSource === 'string' ? JSON.parse(stripeSource)?.card : stripeSource?.card;
		const mm = String(card.exp_month).padStart(2, '0')
		const yyyy = String(card.exp_year).slice(-2)
		return `${mm} / ${yyyy}`
	} catch (err) {
		return 'Cannot display card expiry.'
	}
}

function errorBorder (error) {
	return css({
		borderColor: error && theme.flavours.danger.foregroundColor,
		borderStyle: error && 'solid',
		borderWidth: error && inputSize.medium.borderWidth,
		borderRadius: error && inputSize.medium.borderRadius,
		padding: error && `10px`,
		marginBottom: error && `16px`,
	})
}

const stripeModalStyles = css({
	display: 'block',
	background: 'white',
	margin: 10,
	padding: 20,
	borderRadius: 6,
	outline: 'none',
	minWidth: 400,
	textAlign: 'center',
}).toString()

const stripeModalOverlayStyles = css({
	backgroundColor: 'rgba(0,0,0,0.2)',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'fixed',
	zIndex: 100000,
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
}).toString()

export const StripeElementsInput = ({ value, disabled = false, appConfig, paymentMethod, setStripeCustomerId, currency = 'AUD' }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [error, setError] = useState('');
	const stripePromise = loadStripe(appConfig.STRIPE_PUBLISHABLE_KEY);
	const options = { mode: 'setup', currency: currency.toLowerCase() };
	console.log(appConfig)

	const methodToRender = value || paymentMethod;
	const last4 = methodToRender ? last4FromStripeSource(methodToRender) : '';
	const expiry = methodToRender ? expiryFromStripeSource(methodToRender) : '';

	if (disabled) {
		return (
			<div>
				<ColContainer columnTemplate="1fr 1fr 1fr">
					<div>Card number</div>
					<div>{last4}</div>
				</ColContainer>
				<HR spaceAbove="small" spaceBelow="small" />
				<ColContainer columnTemplate="1fr 1fr 1fr">
					<div>Card expiry</div>
					<div>{expiry}</div>
				</ColContainer>
				<HR spaceAbove="small" spaceBelow="small" />
			</div>
		)
	}

	return (
		<div>
			{!methodToRender ? (
				<ColContainer columnTemplate="1fr 1fr 1fr">
				<Button onClick={() => setModalOpen(true)}>+ Add Card</Button>
				<div className={errorBorder(error)}>
						<span className={css({
							color: error && theme.flavours.danger.foregroundColor,
						})} >
							Add customer card details to authorise parking fee
						</span>
				</div>
			</ColContainer>
			) : (
				<>
					<ColContainer columnTemplate='1fr 1fr 1fr'>
						<div>Card number</div>
						<div>{last4}</div>
					</ColContainer>
					<HR spaceAbove='small' spaceBelow='small' />
					<ColContainer columnTemplate='1fr 1fr 1fr'>
						<div>Card expiry</div>
						<div>{expiry}</div>
					</ColContainer>
					<HR spaceAbove='small' spaceBelow='small' />
					<A block flavour='primary' spaceBelow='medium' onClick={() => setModalOpen(true)}>
						Update card details
					</A>
				</>
			)}

			<Elements stripe={stripePromise} options={options}>
				<StripeModal
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}
					error={error}
					setError={setError}
					setStripeCustomerId={setStripeCustomerId}
					platformEndpoint={appConfig.APPLICATION_URL}
				/>
			</Elements>
		</div>
	)
}

const StripeModal = ({ modalOpen, setModalOpen, error, setError, setStripeCustomerId, platformEndpoint }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements || loading) return;

		setLoading(true);
		const { error: submitError } = await elements.submit();
		if (submitError) {
			setError('Please enter valid card details');
			setLoading(false);
			return null;
		}

		const res = await fetch(
			`${platformEndpoint}/create-intent`,
			{ method: 'POST', headers: { 'Content-Type': 'application/json' } },
		);
		const { client_secret: clientSecret, customer_id: stripeCustomerId } = await res.json();

		const { error } = await stripe.confirmSetup({
			elements,
			clientSecret,
			redirect: 'if_required',
			confirmParams: {
				return_url: window.location.origin,
			},
		});

		if (error) {
			setLoading(false);
			setError('Invalid payment method. Please enter valid card details');
			return;
		}

		setLoading(false);
		setError('');
		setStripeCustomerId(stripeCustomerId);

		setModalOpen(false);
	};

	return (
		<ReactModal
			isOpen={modalOpen}
			onRequestClose={() => setModalOpen(false)}
			className={stripeModalStyles}
			overlayClassName={stripeModalOverlayStyles}
		>
			{ error }
			<PaymentElement />
			<br/>
			<Button
				type="button"
				onClick={() => setModalOpen(false)}
			>
				Cancel
			</Button>
			<Button
				type="submit"
				disabled={loading}
				style={{ marginLeft: '1rem' }}
				onClick={(e) => handleSubmit(e)}
			>
				Add Details
			</Button>
		</ReactModal>
	);
};
