import gql from 'graphql-tag'

export const createBookingMutation = gql`
	mutation createBooking (
		$installationId: ID!
		$rateCardId: ID!
		$plate: String!
		$staffName:String
		$startDay: String
		$endDay: String

		$entryEventId: ID

		$guestName: String
		$guestEmail: String
		$guestPhone: String
		$guestRoom: String
		$guestReservation: String
		$stripeCustomerId: String
		$carType: String
		$carNotes: String
	) {
		createBooking(
			booking: {
				installationId: $installationId
				rateCardId: $rateCardId
				plate: $plate
				staffName:$staffName
				startDay: $startDay
				endDay: $endDay

				entryEventId: $entryEventId

				guestName: $guestName
				guestEmail: $guestEmail
				guestPhone: $guestPhone
				guestRoom: $guestRoom
				guestReservation: $guestReservation
				stripeCustomerId: $stripeCustomerId
				carType: $carType
				carNotes: $carNotes
			}
		) {
			id
			guestName
		}
	}
`

export const updateBookingMutation = gql`
	mutation updateBooking (
		$installationId: ID!
		$bookingId: ID!
		$rateCardId: ID!
		$plate: String!
		$startDay: String
		$endDay: String
		$staffName:String

		$guestName: String
		$guestEmail: String
		$guestPhone: String
		$guestRoom: String
		$guestReservation: String
		$stripeCustomerId: String
		$status: BookingStatusEnum
		$carType: String
		$carNotes: String
	) {
		updateBooking(
			booking: {
				installationId: $installationId
				id: $bookingId
				rateCardId: $rateCardId
				plate: $plate
				startDay: $startDay
				endDay: $endDay
				staffName:$staffName
				guestName: $guestName
				guestEmail: $guestEmail
				guestPhone: $guestPhone
				guestRoom: $guestRoom
				guestReservation: $guestReservation
				stripeCustomerId: $stripeCustomerId
				status: $status
				carType: $carType
				carNotes: $carNotes
			}
		) {
			id
		}
	}
`

export const createEmailMutation = gql`
	mutation createEmail (
		$installationId: ID!,
		$relatedId: ID!,
		$settingsJson: String
	) {
		createEmail(
			email: {
				installationId: $installationId
				relatedId: $relatedId
				settingsJson: $settingsJson
			}
		) {
			id
		}
	}
`

export const openGateMutation = gql`
	 mutation GateControl ($id: ID!) {
        openGate(gateId: $id) {
              updatedAt
              flag
        }
    }
`

export const createRefundMutation = gql`
	mutation createRefund (
		$bookingId: ID!,
		$installationId: ID!,
		$amountAud: String!
	) {
		createRefund(
			refund: {
				bookingId: $bookingId
				installationId: $installationId
				amountAud: $amountAud
			}
		) {
			id
			bookingId
			amountAud
			status
		}
	}
`

export const createCancellationRequestMutation = gql`
	mutation createCancellationRequest($relatedBookingId: ID!) {
		createCancellationRequest(cancellationRequest: { relatedBookingId: $relatedBookingId }) {
			id
			relatedBookingId
		}
	}
`

export const deleteBookingMutation = gql`
	mutation deleteBooking($bookingId:ID!){
		deleteBooking(bookingId:$bookingId)
	}
`

export const restartQuestTerminal = gql`
	mutation updateGateQuestTerminalStatus($gateId: ID!) {
		updateGateQuestTerminalStatus(gateId: $gateId) {
			id
		}
	}
`

export const updateSign = gql`
	mutation UpdateSign($id: ID!, $input: UpdateSignInput!) {
		updateSign(id: $id, input: $input) {
			id
		}
	}
`

export const cancelChargeV2 = gql`
		mutation CancelChargeV2($id: ID!) {
				cancelChargeV2(id: $id)
		}
`
