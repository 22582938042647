import React, {useEffect, useState} from 'react'
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import home from "../icons/sidebar/homeIcon.svg";
import bookingIcon from "../icons/sidebar/bookingIcon.svg";
import guestCardIcon from "../icons/sidebar/guestCardIcon.svg";
import parkingLotIcon from "../icons/sidebar/parkingLotIcon.svg";
import cameraStreamsIcon from "../icons/sidebar/cameraStreamsIcon.svg";
import dataExportIcon from "../icons/sidebar/dataExportIcon.svg";
import payoutsIcon from "../icons/sidebar/payoutsIcon.svg";
import transactionIcon from "../icons/sidebar/transactionIcon.svg";
import supportIcon from "../icons/sidebar/supportIcon.svg";
import sanctionedPlateIcon from "../icons/sidebar/sanctionedPlateIcon.svg";
import sanctionedTimeIcon from "../icons/sidebar/sanctionedTimeIcon.svg";
import dashboardIcon from "../icons/sidebar/dashboardIcon.svg";
import users from "../icons/sidebar/users.svg";
import logoutIcon from "../icons/sidebar/logoutIcon.svg";
import {LogoBox} from "./LogoBox";
import {NavLink, useHistory} from "react-router-dom";
import {NotificationImportantOutlined} from '@material-ui/icons'
import {useSubscription} from "@apollo/client";
import gql from 'graphql-tag'

import authManager from '../lib/authManager';
import {AdminOnly, MemberOnly, RootOnly} from './AdminOnly';
import * as theme from "../theme";
import {css} from "glamor";
import Badge from "@material-ui/core/Badge";
import drivoApollo from "../lib/drivoApollo";

const useStyles = makeStyles({
    mainCard: {
        borderRadius: '14px',
        background: '#fff',
        boxShadow: '0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)',
        transition: '.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
        // padding: '35px 35px 45px 15px',
    },
    listItem: {
        '&:hover': {
            borderLeft: '2px solid #538800',
            backgroundImage: 'linear-gradient(to right,#edfbda, white)'

        }
    },
    activeClassName: {
        borderLeft: '2px solid #538800',
        backgroundImage: 'linear-gradient(to right,#edfbda, white)'
    }
});


const itemStyle = css({
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    // paddingLeft: 10,
    paddingRight: 10,
    height: 70,
    // ':first-child': {marginLeft: -10},
    // ':last-child': {marginRight: -10},
});

function PrimaryNavLink({component: Component = NavLink, ...rest}) {
    return (
        <Component
            className={css(itemStyle, {
                fontSize: 12,
                borderBottomWidth: 1,
                borderBottomColor: 'transparent',
                borderBottomStyle: 'solid',
                color: theme.black,
                cursor: 'pointer',
                textDecoration: 'none',
                transition: 'all 0.3s',
                backgroundColor: theme.white,
                ':visited': {color: theme.black},
                ':hover': {
                    color: theme.blue,
                    // borderBottomColor: theme.blue,
                },
            }).toString()}
            {...rest}
        />
    );
}

export default function SideBar({installation, match, appConfig}) {
    function handleLogout() {
        return authManager.logout()
    }

    const [notification, setNotification] = useState(false)
    const {data = {}, loading, error} = useSubscription(gql`
        subscription notificationComment{
            notificationComment{
                createdById
            }
        }
    `, {
        client: drivoApollo,
        fetchPolicy: 'no-cache',
        shouldResubscribe: true
    })
    useEffect(() => {
        if (!loading && data && data?.notificationComment) {
            if (data?.notificationComment.createdById !== authManager.userId && data?.notificationComment.createdById !== "") {
                setNotification(true)
            } else {
                setNotification(false)
            }
        } else {
            setNotification(false)
        }
    }, [data, loading])

    const classes = useStyles();
    const history = useHistory()
    return (
        <Container className={classes.mainCard}>
            <List>

                <MemberOnly>
                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/dashboard`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={dashboardIcon}/> </ListItemIcon>
                            <ListItemText primary={"Dashboard"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>

                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/new-booking`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={home}/> </ListItemIcon>
                            <ListItemText primary={"Home"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>

                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/bookings`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={bookingIcon}/> </ListItemIcon>
                            <ListItemText primary={"Bookings"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>

                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/upcoming-extras`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={users}/> </ListItemIcon>
                            <ListItemText primary={"Upcoming Parking Extras"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>

                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/support`}>
                        <ListItem button>
                            <Badge variant={"standard"}
                                   invisible={!notification}
                                   badgeContent={<NotificationImportantOutlined color={"primary"}
                                                                                style={{fontSize: '13px'}}/>}>
                                <ListItemIcon> <LogoBox size={30} src={supportIcon}/> </ListItemIcon>
                                <ListItemText primary={"Support"} variant={"h5"} />
                            </Badge>
                        </ListItem><br/>
                    </PrimaryNavLink>


                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/streams`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={cameraStreamsIcon}/> </ListItemIcon>
                            <ListItemText primary={"Camera Streams"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>
                </MemberOnly>

                <AdminOnly>
                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/settings/guestcards`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={guestCardIcon}/> </ListItemIcon>
                            <ListItemText primary={"Guest cards"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>

                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/settings/lots`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={parkingLotIcon}/> </ListItemIcon>
                            <ListItemText primary={"Parking Lots"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>

                    {/*<PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/settings/vouchery`}>*/}
                    {/*    <ListItem button>*/}
                    {/*        <ListItemIcon> <LogoBox size={30} src={sancitionedPlatesIcon}/> </ListItemIcon>*/}
                    {/*        <ListItemText primary={"Vouchery"} variant={"h5"} />*/}
                    {/*    </ListItem><br/>*/}
                    {/*</PrimaryNavLink>*/}

                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/settings/payouts`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={payoutsIcon}/> </ListItemIcon>
                            <ListItemText primary={"Payouts"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>

                    <RootOnly>
                        <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/settings/transactions`}>
                            <ListItem button>
                                <ListItemIcon> <LogoBox size={30} src={transactionIcon}/> </ListItemIcon>
                                <ListItemText primary={"Transactions"} variant={"h5"} />
                            </ListItem><br/>
                        </PrimaryNavLink>
                    </RootOnly>

                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/settings/bookings`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={dataExportIcon}/></ListItemIcon>
                            <ListItemText primary={"Data Export"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>

                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/settings/sanctioned`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={sanctionedTimeIcon}/> </ListItemIcon>
                            <ListItemText primary={"Sanctioned Times"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>


                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/settings/users`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={users}/> </ListItemIcon>
                            <ListItemText primary={"Users"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>

                    <PrimaryNavLink activeClassName={classes.activeClassName} to={`${match.url}/settings/plates`}>
                        <ListItem button>
                            <ListItemIcon> <LogoBox size={30} src={sanctionedPlateIcon}/> </ListItemIcon>
                            <ListItemText primary={"Sanctioned Plates"} variant={"h5"} />
                        </ListItem><br/>
                    </PrimaryNavLink>
                </AdminOnly>

                <Divider/>
                <PrimaryNavLink component="div" onClick={handleLogout}>
                    <ListItem button>
                        <ListItemIcon> <LogoBox size={30} src={logoutIcon}/> </ListItemIcon>
                        <ListItemText primary={"Logout"} variant={"h5"} />

                    </ListItem>
                </PrimaryNavLink>
            </List>
        </Container>
    )
}
