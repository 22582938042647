import React from 'react';
import { Alert } from './Alert';

function interpretGraphqlError (error, knownErrorCodes = {}) {
	if (error.networkError) {
		return 'There was a problem contacting the server. Please try again later.';
	}

	console.error('GraphQLError', error); // eslint-disable-line no-console
	if (error.graphQLErrors && error.graphQLErrors.length) {
		const messages = error.graphQLErrors.reduce((acc, e) => {
			if (e.code && knownErrorCodes[e.code]) {
				acc.push(knownErrorCodes[e.code]);
			}
			return acc;
		}, []);

		if (messages.length > 0) {
			return messages.join(' ');
		}

		return error.message.replace('GraphQL error: ', '');
	}

	if (error.paymentStripe) return error.message;

	return 'There was an error processing your request. Please try again later.';
}

export function GraphqlErrorMessage ({ error, knownErrorCodes, ...props }) {
	return <Alert flavour="danger" {...props}>
		{interpretGraphqlError(error, knownErrorCodes)}
	</Alert>
}

export function ValidationErrorMessage ({ error, ...props }) {
	return <Alert flavour="danger" {...props}>
		<strong>Validation error</strong>
		<br />
		{error ? (error.message || error) : 'Please check the highlighted fields.'}
	</Alert>
}
