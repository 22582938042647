import React, { PureComponent, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { css } from 'glamor';
import { graphql } from "@apollo/client/react/hoc";
import { configureForm } from '@thinkmill/pragmatic-forms';
import compose from 'lodash/flowRight';
import { withState } from '../../../lib/StateFully';
import authManager from '../../../lib/authManager';
import * as theme from '../../../theme';
import trashIcon from '../../../icons/trash.svg';
import { withInstallation } from '../../../components/InstallationContext';
import { Input } from '../../../components/Input';
import { CheckboxInput } from '../../../components/CheckboxInput';
import { Button, HollowButton, NakedButton } from '../../../components/Button';
import { Container, CardContainer } from '../../../components/Container';
import { H1, H2, Byline } from '../../../components/Typography';
import { Well } from '../../../components/Well';
import { Table, THead, TBody } from '../../../components/Table';
import { LoadingFork } from '../../../components/LoadingFork';
import { ColContainer } from '../../../components/Container';
import { SearchInput } from '../../../components/SearchInput';
import { CreateForm } from './CreateForm';
import { useIsVisible } from 'react-is-visible'

import {
    listSanctionedPlatesQuery,
    updateSanctionedPlateQuery,
    deleteSanctionedPlateQuery,
} from './queries';
import { Left, Right } from "../../../components/Header";
import { DRIVO_URL } from "../../../lib/drivoApollo";
import { PlateRow } from "./PlateRow";
import { allLots } from "../ParkingLots/queries";
import { SelectInput } from "../../../components/SelectInput";
import ReactModal from 'react-modal';
import { SingleDateInput } from '../../../components/SingleDateInput';
import moment from 'moment';


const validatePlate = (formData) => {
    const errors = {};
    if (!formData.plate.trim().length) {
        errors.plate = 'Please enter a plate';
    }
    return errors;
};

const textCenter = css({ textAlign: 'center' });
function Header(props) {
    return <div
        className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch',
        })}
        {...props}
    />
}

const FieldCell = (props) => (
    <td
        className={css({
            position: 'relative',
        })}
    >
        <div
            className={css({
                // paddingLeft: 10,
                paddingRight: 20,
                '& input': {
                    height: 48,
                    marginBottom: 0,
                    backgroundColor: theme.gray05,
                    color: theme.gray60,
                    fontSize: 'inherit',
                },
            })}
            {...props}
        />
    </td>
);


const PlateFormRow = configureForm({
    initFields: ({ plate }) => ({
        id: plate.id,
        plate: plate.plate || '',
        label: plate.label || '',
        lotId: plate.lot ? plate.lot.id : null,
        isEnabled: plate.isEnabled,
    }),
    validate: validatePlate,
    submit: (formData, props) => props.onSubmit(formData),
    onSuccess: (res, props) => props.onSuccess(),
})(({ form, onCancel, focusField, lotsOptions }) => (
    <tr>
        <FieldCell>
            <Input
                autoFocus={focusField === 'plate'}
                size="small"
                {...form.getFieldProps({ type: 'text', name: 'plate' })}
            />
        </FieldCell>
        <FieldCell>
            <Input
                autoFocus={focusField === 'label'}
                size="small"
                {...form.getFieldProps({ type: 'text', name: 'label' })}
            />
        </FieldCell>
        <FieldCell>
            {lotsOptions && <SelectInput
                className={css({ flex: 2, marginRight: 20 })}
                options={[{ label: "Default", value: null },
                ...lotsOptions.map(l => { return { value: l.id, label: `${l.name} (${l.description})` } })]}
                {...form.getFieldProps({ name: 'lotId', type: 'select' })}
            />}
        </FieldCell>
        <FieldCell className={textCenter}>
            <CheckboxInput
                autoFocus={focusField === 'isEnabled'}
                {...form.getFieldProps({ type: 'checkbox', name: 'isEnabled' })}
            />
        </FieldCell>
        <FieldCell>
            <div className={css({ display: 'flex', justifyContent: 'center' })}>
                <HollowButton size="small" type="button" spaceBelow="none" onClick={form.onSubmit}>
                    Save
                </HollowButton>
                <NakedButton size="small" flavour="gray" spaceBelow="none" onClick={onCancel}>
                    Cancel
                </NakedButton>
            </div>
        </FieldCell>
    </tr>
));

class SanctionedPlates extends PureComponent {

    handleOnSubmit = ({ id, plate, label, isEnabled, lotId }) => {
        if (lotId === "Default") {
            lotId = null;
        }
        return this.props.updatePlate({
            variables: {
                id,
                plate,
                label,
                isEnabled,
                lotId,
                currentUser: authManager.userId,
            },
        });
    };

    handleOnSuccess = () => {
        this.props.setState({ editing: null });
        this.props.listPlates.refetch();
    };

    handleOnCancel = () => {
        this.props.setState({ editing: null });
    };

    handleOnDelete = (plate) => () => {
        this.props.setState({ deleting: plate.id });
    };

    handleOnDeleteConfirm = (plate) => async () => {
        try {
            await this.props.deletePlate({
                variables: { id: plate.id },
            });
            this.props.setState({ deleting: null });
            this.props.listPlates.refetch();
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    handleOnDeleteCancel = () => {
        this.props.setState({ deleting: null });
    };

    handleModalOpen = () => {
        this.props.setState({ isModalOpen: true });
    }

    handleFromDateChange = (date) => {
        const formatted = moment(date).format("YYYY-MM-DD");

        this.props.setState({ fromDate: formatted });
    }

    handleToDateChange = (date) => {
        const formatted = moment(date).format("YYYY-MM-DD");

        this.props.setState({ toDate: formatted });
    }

    render() {
        const { listPlates, state, setState, installation, facility, allLots } = this.props;
        const lotsOptions = allLots ? allLots.allLots : [];
        const filteredPlates = (listPlates.allSanctionedPlates || []).filter(
            ({ plate, label }) =>
                plate.toLowerCase().includes(state.search.toLowerCase()) ||
                label.toLowerCase().includes(state.search.toLowerCase()),
        );

        return (
            <div>
                <CardContainer>
                    <Helmet>
                        <title>Sanctioned Plates</title>
                    </Helmet>
                    <Container size="large" spaceAbove="small" spaceBelow="none">
                        <Header>
                            <Left>
                                <H1>Sanctioned Plates</H1>
                            </Left>
                            <Right>
                                <Button target={"_blank"} href={`${DRIVO_URL}/sanctioned-report/${installation.id}/download`}>Report (3 months)</Button>
                            </Right>
                        </Header>

                    </Container>
                    <Container size="large" spaceAbove="small" spaceBelow="medium">
                        <ReactModal
                            isOpen={state.isModalOpen}
                            onRequestClose={() => { this.props.setState({ isModalOpen: false }) }}
                            className={css({
                                display: "block",
                                background: "white",
                                margin: 10,
                                padding: 30,
                                borderRadius: 6,
                                outline: "none",
                                minWidth: 400,
                                minHeight: 500,
                            }).toString()}
                            overlayClassName={css({
                                backgroundColor: "rgba(0,0,0,0.2)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "fixed",
                                zIndex: 100000,
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }).toString()}
                        >
                            <div
                                className={css({
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-end',
                                })}
                            >
                                <div style={{ marginRight: "5px" }}>
                                    <SingleDateInput
                                        label="From Date"
                                        onChange={this.handleFromDateChange}
                                        date={state.fromDate}
                                    />
                                </div>
                                <div style={{ marginRight: "5px" }}>
                                    <SingleDateInput
                                        label="To Date"
                                        onChange={this.handleToDateChange}
                                        date={state.toDate}
                                    />
                                </div>
                                <Button
                                    type="submit"
                                    onClick={() => setState({ isModalOpen: false })}
                                    href={`${DRIVO_URL}/sanctioned-report/${installation.id}/download/custom-date/${state.fromDate}/${state.toDate}`}
                                >
                                    Get Report
                                </Button>
                            </div>
                        </ReactModal>
                    </Container>
                    <Container size="large" spaceAbove="small" spaceBelow="medium">
                        <Byline>Add new sanctioned plate</Byline>
                        <CreateForm onCreateSuccess={listPlates.refetch} lotsOptions={lotsOptions} />
                    </Container>
                    <Container size="large" spaceAbove="small" spaceBelow="medium">
                        <ColContainer>
                            <SearchInput
                                autoFocus
                                onChange={(e) => setState({ search: e.target.value })}
                                placeholder="Search license plate or description"
                                value={state.search}
                            />
                        </ColContainer>
                    </Container>
                    <Container spaceBelow="large">
                        <LoadingFork
                            isLoading={listPlates.loading}
                            error={listPlates.error}
                            render={() => {
                                if (filteredPlates.length === 0) {
                                    return (
                                        <Well>
                                            <div>
                                                <H2>No plates available</H2>
                                                <Byline spaceBelow="none">
                                                    Use the form above to create your first sanctioned
                                                    plate
                                                </Byline>
                                            </div>
                                        </Well>
                                    );
                                }

                                return (
                                    <Table>
                                        <THead>
                                            <tr>
                                                <th>Plate</th>
                                                <th>Description</th>
                                                <th>Lot</th>
                                                <th>Active</th>
                                                <th />
                                            </tr>
                                        </THead>
                                        <colgroup>
                                            <col className={css({ width: '20%' })} />
                                            <col className={css({})} />
                                            <col className={css({})} />
                                            <col className={css({ width: '60px' })} />
                                            <col className={css({ width: '200px' })} />
                                        </colgroup>
                                        <TBody>
                                            {filteredPlates.map((plate) => {
                                                if (state.editing === plate.id) {
                                                    return (
                                                        <PlateFormRow
                                                            plate={plate}
                                                            focusField={state.focusField}
                                                            key={plate.id}
                                                            lotsOptions={lotsOptions}
                                                            onSubmit={this.handleOnSubmit}
                                                            onSuccess={this.handleOnSuccess}
                                                            onCancel={this.handleOnCancel}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlateRow
                                                        key={plate.id}
                                                        plate={plate}
                                                        deleting={state.deleting === plate.id}
                                                        onEdit={(focusField) =>
                                                            setState({
                                                                editing: plate.id,
                                                                focusField,
                                                            })
                                                        }
                                                        onDelete={this.handleOnDelete(plate)}
                                                        onDeleteConfirm={this.handleOnDeleteConfirm(
                                                            plate,
                                                        )}
                                                        facilityId={facility.id}
                                                        onDeleteCancel={this.handleOnDeleteCancel}
                                                    />
                                                );
                                            })}
                                        </TBody>
                                    </Table>
                                );
                            }}
                        />
                    </Container>
                </CardContainer>
            </div>
        );
    }
}

export default compose(
    withInstallation,
    graphql(listSanctionedPlatesQuery, {
        name: 'listPlates',
        options: (props) => ({
            variables: {
                installationId: props.installation.id,
            },
        }),
    }),
    graphql(allLots, {
        name: 'allLots',
        options: (props) => ({
            variables: {
                installationId: props.installation.id,
            },
        }),
    }),
    graphql(updateSanctionedPlateQuery, { name: 'updatePlate' }),
    graphql(deleteSanctionedPlateQuery, { name: 'deletePlate' }),
    withState(() => ({
        editing: null,
        isModalOpen: false,
        fromDate: "",
        toDate: "",
        search: "",
    })),
)(SanctionedPlates);
