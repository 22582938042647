import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";

export const DRIVO_URL = process.env.REACT_APP_API_URL;

const httpLink = new HttpLink({
  uri: `${DRIVO_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };
});

export default new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});
