import React, { useEffect, useRef, useState } from "react";
import { useIsVisible } from "react-is-visible";
import { CheckboxInput } from "../../../components/CheckboxInput";
import { css } from "glamor";
import { HollowButton, NakedButton } from "../../../components/Button";
import trashIcon from "../../../icons/trash.svg";
import { isPlateInLot } from "../RateCards/queries";
import drivoApollo from "../../../lib/drivoApollo";

const textRight = css({ textAlign: 'right' });
const textCenter = css({ textAlign: 'center' });

export const PlateRow = ({ plate, deleting, onEdit, onDelete, onDeleteConfirm, onDeleteCancel, facilityId }) => {
    const nodeRef = useRef()
    const isVisible = useIsVisible(nodeRef)

    const [haveFetchedPlateStatus, setHaveFetchedPlateStatus] = useState(false);
    const [isPlateInLotStatus, setIsPlateInLotStatus] = useState(null);

    useEffect(() => {
        const getPlateInLot = async () => {
            if (isVisible && !haveFetchedPlateStatus) {
                const { data } = await drivoApollo.query({
                    query: isPlateInLot,
                    fetchPolicy: 'network-only',
                    variables: { id: facilityId, plate: plate.plate },
                });
                if (data && data.facility) {
                    setIsPlateInLotStatus(data.facility.isPlateInLot);
                    setHaveFetchedPlateStatus(true);
                }
            }
        }
        getPlateInLot();
    }, [isVisible])

    return <tr ref={nodeRef}>
        <td onClick={() => onEdit('plate')}>
            <div>{plate.plate}</div>
        </td>
        <td onClick={() => onEdit('label')}>
            <div>{plate.label}</div>
        </td>
        <td onClick={() => onEdit('lotId')}>
            {plate.lot && <div>{plate.lot.name}</div>}
        </td>
        <td onClick={() => onEdit('isEnabled')} className={textCenter}>
            <CheckboxInput disabled checked={plate.isEnabled} />
        </td>
        {!deleting && (
            <td className={textCenter}>
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    })}
                >
                    <NakedButton flavour="gray" size="small" spaceBelow="none" onClick={onEdit}>
                        Edit
                    </NakedButton>
                    <NakedButton flavour="gray" size="small" spaceBelow="none" onClick={onDelete}>
                        <img src={trashIcon} alt="Delete" />
                    </NakedButton>
                </div>
            </td>
        )}
        {deleting && (
            <td className={textRight}>
                <HollowButton
                    flavour="danger"
                    size="small"
                    spaceBelow="none"
                    onClick={onDeleteConfirm}
                >
                    Delete
                </HollowButton>
                <NakedButton flavour="gray" size="small" spaceBelow="none" onClick={onDeleteCancel}>
                    Cancel
                </NakedButton>
            </td>
        )}
    </tr>
};
