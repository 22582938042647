import React, { useState, useEffect } from 'react'
import RateCardLabel from './components/RateCardLabel'
import { AdminOnly, RootOnly } from '../../components/AdminOnly';
import { BookingStatus } from './components/BookingStatus';
import { Button, HollowButton } from '../../components/Button';
import { Container, CardContainer, ColContainer } from '../../components/Container';
import { H1, H3, H4, HR, Byline } from '../../components/Typography';
import { Loading, LoadingError } from '../../components/LoadingFork';
import { StatusPill } from '../../components/StatusPill';
import { StripeElementsInput } from '../../components/StripeElementsInput';
import { Table, TBody, TD, TR } from '../../components/Table';
import { css } from 'glamor'
import { gray40 } from '../../theme'
import { useQuery } from "@apollo/client"
import { withAppConfig } from '../../components/AppConfigContext';
import { withInstallation } from '../../components/InstallationContext';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { deleteBookingMutation } from './mutations'
import { getStripePaymentMethods } from "./queries";

import ResendEmail from './ResendEmail'
import RequestRefund from './RequestRefund'
import {
    details,
    formatCurrency,
    format24HPeriod, toTitleCase
} from '../../lib/rateCardUtils'
import {
    bookingById
} from './queries'
import drivoApollo from "../../lib/drivoApollo";
import { useHistory } from "react-router-dom";
import { fmtDate } from "../../lib/misc";
import RequestCancellation from './RequestCancellation';

const FLAVOUR_MAP = {
    SUCCESS: 'success',
    PENDING: 'warning',
    ERROR: 'danger'
}

function isLocked(booking) {
    if (!booking) return false;
    const { status } = booking;
    return ['FINALISED', 'ERROR'].includes(status)
}

function RowHead(props) {
    return <td
        className={css({
            width: '30%',
            color: gray40,
        })}
        {...props}
    />
}


function CappedPill() {
    return <StatusPill flavour='warning' size='xsmall'>Capped</StatusPill>
}

function ExpiryPill() {
    return <StatusPill flavour='danger' size='xsmall'>Expired</StatusPill>
}

function BookingDetail({ match, installation, appConfig }) {

    function RefundRow({
        appConfig,
        id,
        status,
        amountAud,
        createdBy,
        createdAt,
        updatedAt,
        currency
    }) {
        const finalisedAt = status === 'PROCESSED' ? updatedAt : null
        const acceptUrl = finalisedAt ? null : `${appConfig.APPLICATION_URL}/refunds/${id}/mark-as-processed`

        return <ChargeRow
            amountAud={amountAud}
            createdAt={createdAt}
            finalisedAt={finalisedAt}
            currency={currency}>
            Refund {acceptUrl ? <RootOnly>
                <a href={acceptUrl} className={css({ 'font-size': 12, color: gray40, })}>(mark as processed)</a>
            </RootOnly> : null}
        </ChargeRow>
    }

    function ChargeRow({
        amountAud,
        error,
        createdBy,
        createdAt,
        finalisedAt,
        currency,
        children
    }) {
        const status = error ? 'ERROR' : (finalisedAt ? 'SUCCESS' : 'PENDING')

        const dateStr = fmtDate(new Date(finalisedAt || createdAt).toISOString(), installation.timeZoneName, 'EEE, d MMM yyyy HH:mm ZZZZ')

        return <TR>
            <RowHead>{children}</RowHead>
            <TD>
                {dateStr}
                {(createdBy && createdBy.name) ? <>
                    <br />
                    <small style={{ color: gray40 }}>{createdBy.name}</small>
                </> : null}
                {error ? <>
                    <br />
                    <small style={{ color: gray40 }}>{error}</small>
                </> : null}
            </TD>
            <TD>
                {formatCurrency(Number(amountAud), currency)}{' '}
                <StatusPill flavour={FLAVOUR_MAP[status]} size='xsmall'>{status}</StatusPill>
            </TD>
        </TR>
    }

    function EmailRow({
        appConfig,
        id,
        mailgunId,
        templateClass,
        finalisedAt
    }) {
        const status = finalisedAt ? (mailgunId ? 'SUCCESS' : 'ERROR') : 'PENDING'
        const viewUrl = `${appConfig.APPLICATION_URL}/emails/${id}`

        return <TR>
            <RowHead>Email<RootOnly> <a href={viewUrl} className={css({ 'font-size': 12, color: gray40, })}>(view in
                browser)</a></RootOnly></RowHead>
            <TD>{finalisedAt ? fmtDate(new Date(finalisedAt).toISOString(), installation.timeZoneName, 'EEE, d MMM yyyy HH:mm ZZZZ') : ' '}</TD>
            <TD>
                {templateClass}
                {' '}<StatusPill flavour={FLAVOUR_MAP[status]} size='xsmall'>{status}</StatusPill>
            </TD>
        </TR>
    }

    function EventRow({
        gateType,
        gateDescription,
        pcLoggedAt
    }) {
        return <TR>
            <RowHead>Event</RowHead>
            <TD>
                {fmtDate(pcLoggedAt, installation.timeZoneName, 'EEE, d MMM yyyy HH:mm ZZZZ')}
                <br />
                <small style={{ color: gray40 }}>{gateDescription}</small>
            </TD>
            <TD>{gateType}</TD>
        </TR>
    }

    const [open, setOpen] = React.useState(false);
    const history = useHistory()

    const deleteBooking = async (bookingId) => {
        setOpen(false)
        try {
            await drivoApollo.mutate({
                mutation: deleteBookingMutation,
                variables: {
                    bookingId: bookingId
                }
            });
            history.goBack()

        } catch (e) {
            console.log(e)
        }
    }

    const [booking, setBooking] = useState(null)
    const [bookingDetails, setBookingDetails] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)

    const {bookingId} = match.params
    const {data, loading, error, refetch} = useQuery(bookingById, {
        fetchPolicy: 'no-cache',
        variables: {bookingId}
    })

    useEffect(() => {
        if (!data) return;

        setBooking(data.Booking)
    }, [data])

    useEffect(() => {
        if (!booking) return;

        const fetchData = async () => {
            try {
                const res = await details(booking);
                setBookingDetails(res);
            } catch (e) {
                console.error(e.message)
            }
        };

        const fetchPaymentMethod = async () => {
            if (!booking?.stripeCustomerId) return;

            if (booking.stripeCustomerId) {
                try {
                    const paymentMethod = await drivoApollo.query({
                        query: getStripePaymentMethods,
                        variables: {
                            customerId: booking.stripeCustomerId,
                        },
                    });
                    setPaymentMethod(paymentMethod.data.getStripePaymentMethods[0]);
                } catch (error) {
                    console.error("Error fetching payment method:", error);
                }
            }
        };

        fetchData();
        fetchPaymentMethod();
    }, [booking])

    if (loading) return (
        <CardContainer>
            <Loading />
        </CardContainer>
    )
    if (error) return (
        <CardContainer>
            <LoadingError error={error} />
        </CardContainer>
    )

    const { timeZoneName: tz, currency } = installation
    const hasDetails = booking?.guestName || booking?.guestEmail || booking?.guestPhone || booking?.guestRoom || booking?.guestReservation
    const hasCharged = booking?.charges.length > 0
    const isHourly = booking?.rateCard.type === 'HOURLY'

    function onRefund() {
        refetch()
    }

    const onCancellationRequest = () => {
        refetch()
    }

    const isCancellationProcessed = booking?.cancellationRequests?.[0]?.isProcessed || false;
    const isCancellationAlreadyRequested = booking?.cancellationRequests?.length > 0

    return (
        <CardContainer>
            <div>
                <Container size='large' spaceBelow='medium'>
                    <BookingStatus status={booking?.status} />
                    <H1 spaceBelow='none'>{booking?.plate}</H1>
                    <Byline>Booking Id: #{bookingId}</Byline>
                    <Byline>Created At: {booking?.createdAt}</Byline>
                    <HR />
                    <Table spaceBelow='medium'>
                        <TBody>
                            <TR>
                                <RowHead>Guest card</RowHead>
                                <TD><RateCardLabel rateCard={booking?.rateCard} /></TD>
                            </TR>
                            {booking?.staffName &&
                                <TR>
                                    <RowHead>Staff Name</RowHead>
                                    <TD>{booking?.staffName}</TD>
                                </TR>
                            }

                        </TBody>
                    </Table>
                </Container>
                <Container size='large' spaceBelow='large'>
                    {hasDetails ? <H3>Guest details</H3> : null}
                    {hasDetails ? <Table spaceBelow='medium'>
                        <TBody>
                            {booking?.guestName ? <TR>
                                <RowHead>Name</RowHead>
                                <TD>{booking?.guestName}</TD>
                            </TR> : null}
                            {booking?.guestEmail ? <TR>
                                <RowHead>Email</RowHead>
                                <TD>{booking?.guestEmail}</TD>
                            </TR> : null}
                            {booking?.guestPhone ? <TR>
                                <RowHead>Phone number</RowHead>
                                <TD>{booking?.guestPhone}</TD>
                            </TR> : null}
                            {booking?.guestRoom ? <TR>
                                <RowHead>Room</RowHead>
                                <TD>{booking?.guestRoom}</TD>
                            </TR> : null}
                            {booking?.guestReservation ? <TR>
                                <RowHead>Reservation</RowHead>
                                <TD>{booking?.guestReservation}</TD>
                            </TR> : null}
                            {booking?.carType ? <TR>
                                <RowHead>Car Type</RowHead>
                                <TD>{booking?.carType}</TD>
                            </TR> : null}
                            {booking?.carNotes ? <TR>
                                <RowHead>Car Notes</RowHead>
                                <TD>{booking?.carNotes}</TD>
                            </TR> : null}
                        </TBody>
                    </Table> : null}

                    <H3>Booking Period</H3>
                    <Table spaceBelow='medium'>
                        <TBody>
                            {isHourly ? null : <>
                                <TR>
                                    <RowHead>Booking started at</RowHead>
                                    <TD>{fmtDate(bookingDetails?.startedAt, installation.timeZoneName, "HH:mm ZZZZ")}</TD>
                                    <TD>{fmtDate(bookingDetails?.startedAt, installation.timeZoneName, "EEE, d MMM yyyy")}</TD>
                                </TR>
                                <TR>
                                    <RowHead>Booking start</RowHead>
                                    <TD>{fmtDate(bookingDetails?.scheduled.startAt, installation.timeZoneName, "HH:mm ZZZZ")}</TD>
                                    <TD>{fmtDate(bookingDetails?.scheduled.startAt, installation.timeZoneName, "EEE, d MMM yyyy")}</TD>
                                </TR>
                                <TR>
                                    <RowHead>Booking end</RowHead>
                                    <TD>{fmtDate(bookingDetails?.scheduled.endAt, installation.timeZoneName, "HH:mm ZZZZ")}</TD>
                                    <TD>{fmtDate(bookingDetails?.scheduled.endAt, installation.timeZoneName, "EEE, d MMM yyyy")}</TD>
                                </TR>
                            </>}
                            <TR>
                                <RowHead>First Entry</RowHead>
                                <TD>{fmtDate(bookingDetails?.enteredAt, installation.timeZoneName, "HH:mm ZZZZ")}</TD>
                                <TD>{fmtDate(bookingDetails?.enteredAt, installation.timeZoneName, "EEE, d MMM yyyy")}</TD>
                            </TR>
                            <TR>
                                <RowHead>Last Exit</RowHead>
                                <TD>
                                    {bookingDetails?.expired ? 'Unknown (vehicle missing)' : fmtDate(bookingDetails?.exitedAt, installation.timeZoneName, "HH:mm ZZZZ")}
                                </TD>
                                <TD>
                                    {bookingDetails?.expired ? 'Unknown (vehicle missing)' : fmtDate(bookingDetails?.exitedAt, installation.timeZoneName, "EEE, d MMM yyyy")}
                                </TD>
                            </TR>
                            {bookingDetails?.closedAt ? <TR>
                                <RowHead>Closed</RowHead>
                                <TD>{fmtDate(bookingDetails?.closedAt, installation.timeZoneName, "HH:mm ZZZZ")}</TD>
                                <TD>{fmtDate(bookingDetails?.closedAt, installation.timeZoneName, "EEE, d MMM yyyy")}</TD>
                            </TR> : null}
                        </TBody>
                    </Table>

                    {booking?.events.length ? <RootOnly>
                        <H3>Event History</H3>
                        <Table spaceBelow='medium'>
                            <TBody>
                                {booking?.events.map((event, i) => <EventRow key={i} {...event} />)}
                            </TBody>
                        </Table>
                    </RootOnly> : null}

                    {booking?.emails.length ? <AdminOnly>
                        <H4>Email History</H4>
                        <Table spaceBelow='medium'>
                            <TBody>
                                {booking?.emails.map((x, i) => <EmailRow appConfig={appConfig} {...x} key={i} />)}
                            </TBody>
                        </Table>
                    </AdminOnly> : null}

                    <H3>Parking fee</H3>
                    <Table spaceBelow='medium'>
                        {isHourly ? <>
                            <TBody>
                                <TR>
                                    <RowHead>{bookingDetails?.closedAt ? 'Final Fee' : 'Current Fee'}</RowHead>
                                    <TD>{bookingDetails?.enteredAt ? `${bookingDetails?.billing.hours.total} hours` : 'No entry'}</TD>
                                    <TD>
                                        {formatCurrency(bookingDetails?.billing.fee.total, currency)}
                                        {bookingDetails?.billing.capped ? <> {' '}<CappedPill /> </> : null}
                                        {bookingDetails?.expired ? <> {' '}<ExpiryPill /> </> : null}
                                    </TD>
                                </TR>
                            </TBody>
                        </> : <>
                            <TBody>
                                <TR>
                                    <RowHead>Booked</RowHead>
                                    <TD>{format24HPeriod(bookingDetails?.scheduled.days, booking?.rateCard)}</TD>
                                    <TD>
                                        {formatCurrency(bookingDetails?.billing.scheduled.fee.total, currency)}
                                        {bookingDetails?.expired ? <> {' '}<ExpiryPill /> </> : null}
                                    </TD>
                                </TR>
                                {bookingDetails?.overstayed ? (
                                    <TR>
                                        <RowHead>Overstay</RowHead>
                                        <TD>
                                            {bookingDetails?.billing.overstay.hours.total.toFixed(2)} hours
                                            {bookingDetails?.billing.overstay.capped ? <> {' '}<CappedPill /> </> : null}
                                        </TD>
                                        <TD>{formatCurrency(bookingDetails?.billing.overstay.fee.total, currency)}</TD>
                                    </TR>
                                ) : null}
                            </TBody>
                        </>}

                        {booking?.charges.length ? <TBody>
                            {booking?.charges.map((x, i) => <ChargeRow {...x} key={i} currency={currency}>Charge</ChargeRow>)}
                        </TBody> : null}
                        {booking?.refunds.length ? <TBody>
                            {booking?.refunds.map((x, i) => <RefundRow appConfig={appConfig} {...x} currency={currency} key={i} />)}
                        </TBody> : null}
                    </Table>

                    {booking?.charges.length ? (
                        <AdminOnly>
                            <RequestRefund booking={booking} onRefund={onRefund} />
                        </AdminOnly>
                    ) : null}

                    {booking?.bookingExtras && booking?.bookingExtras.length > 0 && <>
                        <H3>Extras</H3>
                        <Table spaceBelow='medium'>
                            <TBody>
                                {booking?.bookingExtras.map(extra => <TR>
                                    <RowHead>{extra.rateCardExtra.description}</RowHead>
                                    <TD>{toTitleCase(extra.rateCardExtra.type)}</TD>
                                    <TD>
                                        ${Number(extra.rateCardExtra.amount).toFixed(2)}
                                    </TD>
                                </TR>
                                )}

                            </TBody>

                        </Table>
                    </>}


                    {(booking?.stripeSource || paymentMethod) && (
                        <div>
                            <H3>Payment details</H3>
                            <StripeElementsInput
                              appConfig={appConfig}
                              currency={installation.currency}
                              paymentMethod={paymentMethod}
                              setStripeCustomerId={() => {}}
                              value={booking?.stripeSource}
                            />
                        </div>
                    )}
                </Container>

                {installation.id === 'f4fb122c-cc71-430a-9fdb-2e5a7c2f6fa4' &&
                    <AdminOnly>
                        <RequestCancellation
                            booking={booking}
                            onCancellationRequest={onCancellationRequest}
                            isCancellationAlreadyRequested={isCancellationAlreadyRequested}
                            isCancellationProcessed={isCancellationProcessed}
                        />
                    </AdminOnly>
                }

                <ColContainer spaceBelow='medium'>
                    {!isLocked(booking) && (
                        <Button block
                            flavour='primary'
                            to={{
                                pathname: match.url.replace(bookingId, 'create'),
                                search: `?bookingId=${bookingId}`,
                            }}
                        >
                            Edit this booking
                        </Button>
                    )}
                    <ResendEmail booking={booking} hasCharged={hasCharged} />

                </ColContainer>
                {booking?.status === 'PENDING_ENTRY' && (
                    <Button block
                        flavour='danger'
                        onClick={() => {
                            setOpen(true)
                        }}
                    >
                        Delete this booking
                    </Button>
                )}
            </div>

            {/*    dialog for delete confirmation */}
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this booking ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <HollowButton
                        size={"small"}
                        onClick={() => {
                            setOpen(false)
                        }}>Cancel</HollowButton>
                    <HollowButton
                        size={"small"}
                        flavour='danger'
                        onClick={() => {
                            deleteBooking(bookingId)
                        }} autoFocus>
                        Delete
                    </HollowButton>
                </DialogActions>
            </Dialog>


        </CardContainer>
    )
}

export default withInstallation(withAppConfig(BookingDetail))
