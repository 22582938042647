import React from 'react';
import {alpha, makeStyles, withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import {LogoBox} from "./LogoBox";
// import hotel101 from "../icons/drivo.svg";
import {NavLink} from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import authManager from "../lib/authManager";
import userIcon from '../icons/user.svg';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import {css} from "glamor";
import {CurrentUserName} from "./CurrentUserName";
import * as theme from "../theme";
import logoutIcon from "../icons/sidebar/logoutIcon.svg";
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import drivoApollo from "../lib/drivoApollo";
import moment from "moment/moment";
import {DateTime} from "luxon";
import {MemberOnly} from "./AdminOnly";


const filter = createFilterOptions();

const itemStyle = css({
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    height: 70,
    ':first-child': {marginLeft: -10},
    ':last-child': {marginRight: -10},
});

function PrimaryNavLink({component: Component = NavLink, ...rest}) {
    return (
        <Component
            className={css(itemStyle, {
                fontSize: 14,
                borderBottomWidth: 1,
                borderBottomColor: 'transparent',
                borderBottomStyle: 'solid',
                color: theme.black,
                cursor: 'pointer',
                textDecoration: 'none',
                transition: 'all 0.3s',
                // backgroundColor: theme.white,
                // ':visited': { color: theme.black },
                // ':hover': {
                //     color: theme.blue,
                //     borderBottomColor: theme.blue,
                // },
            }).toString()}
            {...rest}
        />
    );
}


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            // backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.black,
            },
        },
    },
}))(MenuItem);


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    buzzouts: {
        flexGrow: 1,
        marginRight: 30,
        color: "maroon",
        fontSize: 14,
        width: 120
    },
    search: {
        position: 'relative',
        borderRadius: 18,
        backgroundColor: alpha('#eaeaea', 0.35),
        '&:hover': {
            backgroundColor: alpha('#eaeaea', 0.55),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: '41ch',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40ch',
        },
    },
    sectionDesktop: {
        alignItems: 'center',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    largeAvatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    }
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#7FFF00',
        padding: 0,
        opacity: 0.7,
        '&::after': {
            position: 'absolute',
            top: -5,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            content: '"',
        },
    },
}))(Badge);


export default function NavBar({installation, match, appConfig}) {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleLogout() {
        return authManager.logout()
    }

    const {data: dataByDate, refetch: dataByDateRefetch} = useQuery(
        gql`
            query Analytics($installationId: ID!, $date:String!) {
                getDataByDate(installationId:$installationId, date:$date){
                    buzzOutByDate{
                        count
                        amount
                    }
                }

            }
        `,
        {
            client: drivoApollo,
            variables: {
                installationId: installation.id,
                date: DateTime.now().setZone(installation.timeZoneName).toFormat('yyyy-MM-DD')
            },
            fetchPolicy: "no-cache",
            pollInterval: 5 * 60 * 1000
        }
    )
    const dailyAnalytics = dataByDate?.getDataByDate

    const MenuComponent = () => {
        return (
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>
                    <PrimaryNavLink to={`${match.url}/profile`}>
                        <img src={userIcon} className={css({verticalAlign: 'center'})} alt=""/>
                        <span style={{paddingLeft: 10}}>
                            {' '}
                            <CurrentUserName/>
                        </span>
                    </PrimaryNavLink>
                </StyledMenuItem>
                <StyledMenuItem>
                    <LogoBox size={23} src={logoutIcon}/>
                    <span style={{paddingLeft: 10}}>
                        {' '}
                        <ListItemText primary="Logout" onClick={handleLogout}/>
                    </span>
                </StyledMenuItem>
            </StyledMenu>
        )
    }

    const classes = useStyles();
    return (
        <div className={classes.grow}>
            <AppBar position="static" color="transparent">
                <Toolbar>
                    <NavLink to="/">
                        <LogoBox size={50} src={'/DRIVO_Logo.png'}/>
                    </NavLink>

                    <div className={classes.grow}/>


                    <div className={classes.sectionDesktop}>
                        <MemberOnly>
                            {dailyAnalytics?.buzzOutByDate?.amount > 0 && (
                                <div className={classes.buzzouts}>
                                    Buzzouts Today<br/>
                                    Loss: ${dailyAnalytics?.buzzOutByDate?.amount.toLocaleString()}<br/>
                                    Cars: {dailyAnalytics?.buzzOutByDate?.count}
                                </div>
                            )}
                        </MemberOnly>
                        {/*Notification Icon below*/}

                        {/*<IconButton color="inherit" style={{'margin-right': '30px'}}>*/}
                        {/*    <Badge variant="dot" color="primary">*/}
                        {/*        <NotificationsActiveOutlinedIcon fontSize="large"/>*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            badgeContent={<AddIcon fontSize="small"/>}
                        >
                            <Avatar alt="Avatar" className={classes.largeAvatar}
                                    src={`${appConfig.APPLICATION_ASSETS_URL}/images/${
                                        installation.brandingKey
                                    }/square_200x200.png`}/>
                        </StyledBadge>
                        <IconButton color="inherit">
                            <ExpandMoreOutlinedIcon onClick={handleClick} fontSize="medium"/>
                            <MenuComponent/>
                        </IconButton>

                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
