import React, {useEffect, useState} from 'react'
import { Container, CardContainer } from '../../../components/Container';
import {HR} from '../../../components/Typography';
import { Loading } from '../../../components/LoadingFork';
import { css } from 'glamor'
import { withAppConfig } from '../../../components/AppConfigContext';
import { withInstallation } from '../../../components/InstallationContext';
import {Button} from "../../../components/Button";
import {questTable, stripeTable} from "../Payouts/tables";
import {RootOnly} from "../../../components/AdminOnly";
import {DateInput} from "../../../components/DateInput";
import drivoApollo, {DRIVO_URL} from "../../../lib/drivoApollo";
import {useLazyQuery} from "@apollo/client";
import gql from "graphql-tag";
import {Input} from "../../../components/Input";
import {SelectInput} from "../../../components/SelectInput";
import * as enums from "../../../lib/enums";
import {stringify} from "qs";

const approvedOptions = [
  { label: 'Approved ✅', value: 'true' },
  { label: 'Failed ❌', value: 'false' },
  { label: 'Refunded 🔁', value: 'refunded' },
  { label: 'None', value: '' },
]

const getChargesByDateQuery = gql`
    query GetChargesByDate($input: QueryChargeByDateInput!) {
        getChargesByDate(input: $input) {
            questCharges {
                charges {
                    id
                    createdAt
                    updatedAt
                    GatewayCreatedAt
                    amount
                    plate
                    approved
                }
            }
            stripeCharges {
                stripeChargeId
                amountAud
                amountAudHotel
                amountAudService
                createdAt
                bookingId
                guestName
                guestEmail
                plate
                booking_start
                booking_end
                approved
                requestedRefundAt
                processedRefundAt
            }
        }
    }
`;

function Transactions({ installation, appConfig }) {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [installationsToQuery, setInstallationToQuery] = useState('all');
  const [tab, setTab] = useState('stripe');
  const [stripeCharges, setStripeCharges] = useState([]);
  const [questCharges, setQuestCharges] = useState([]);
  const [search, setSearch] = useState('');
  const [approved, setApproved] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const [stripeTotal, setStripeTotal] = useState({ approved: 0, approvedTotal: 0, failed: 0, failedTotal: 0 })
  const [questTotal, setQuestTotal] = useState({ approved: 0, approvedTotal: 0 })
  const [fetchCharges, { data, error }] = useLazyQuery(getChargesByDateQuery, {
    client: drivoApollo,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!data || !data.getChargesByDate) return;

    // calculate total amount for stripe and quest charges
    const stripeCharges = data.getChargesByDate.stripeCharges || [];
    const questCharges = data.getChargesByDate.questCharges.charges || [];
    const approved = stripeCharges.filter(charge => charge.approved === 'true');
    const failed = stripeCharges.filter(charge => charge.approved !== 'true');
    const approvedAmount = approved.reduce((sum, charge) => sum + (Number(charge.amountAud) || 0), 0);
    const failedAmount = failed.reduce((sum, charge) => sum + (Number(charge.amountAud) || 0), 0);
    const questApproved = questCharges.filter(charge => charge.approved);
    const questApprovedAmount = questApproved.reduce((sum, charge) => sum + charge.amount, 0);

    setStripeTotal({
      approved: approved.length,
      approvedTotal: approvedAmount,
      failed: failed.length,
      failedTotal: failedAmount,
    });
    setQuestTotal({
      approved: questApproved.length,
      approvedTotal: parseFloat((questApprovedAmount / 100).toFixed(2)),
    })

    setStripeCharges(stripeCharges);
    setQuestCharges(questCharges);
    setLoading(false);
  }, [data]);

  const handleFetchCharges = () => {
    if (!startDate || !endDate) {
      alert("Please select a start and end date.");
      return;
    }

    if (new Date(startDate) > new Date(endDate)) {
      alert("Start date cannot be later than the end date.");
      return;
    }

    if(!installationsToQuery){
        alert("Please select an installation to query!");
        return;
    }

    setSearch('');
    setApproved('');
    const query = {
        installationId: installationsToQuery === "current" ? installation.id : undefined,
        startDate,
        endDate
    }
    setDownloadLink(`${DRIVO_URL}/transactions/download?${stringify(query)}`)
    setStripeCharges([]);
    setQuestCharges([]);
    setLoading(true);

    fetchCharges({
      variables: {
        input: {
          installationId: installationsToQuery === "current" ? installation.id : undefined,
          startDate,
          endDate,
        },
      },
      onError: () => setLoading(false),
    });
  };

  const handleFilters = (charges) => {
    let chargesToFilter = charges;
    // check if charges are present to filter them
    if (!chargesToFilter || chargesToFilter.length === 0) return;

    // filter by key phrase
    const searchValue = search.toLowerCase();
    chargesToFilter = chargesToFilter.filter((charge) => {
      const plate = charge.plate ? charge.plate.toLowerCase() : '';
      const name = charge.guestName ? charge.guestName.toLowerCase() : '';
      const email = charge.guestEmail ? charge.guestEmail.toLowerCase() : '';
      return plate.includes(searchValue) || name.includes(searchValue) || email.includes(searchValue);
    });

    // filter by approved status
    if (approved) {
      if (approved === 'refunded') chargesToFilter = chargesToFilter.filter((charge) => charge.requestedRefundAt && charge.processedRefundAt);
      else chargesToFilter = chargesToFilter.filter((charge) => String(charge.approved) === approved);
    }

    return chargesToFilter;
  };

  useEffect(() => {
    if (!data || !data.getChargesByDate || tab === 'refunds') return;

    setStripeCharges(handleFilters(data.getChargesByDate.stripeCharges));
    setQuestCharges(handleFilters(data.getChargesByDate.questCharges.charges));
  }, [search, approved]);

  return (
    <RootOnly>
      <CardContainer>
        <Container size='large' spaceBelow='medium'>
          <div style={{display: 'flex', gap: '1rem', width: '100%'}}>
            <DateInput floatPicker
                       placeholder='Enter start date'
                       autoComplete='off'
                       displayFormat='ddd, D MMM YYYY'
                       type='text'
                       value={startDate}
                       onChange={setStartDate}
                       style={{flex: 1}}
            />

            <DateInput floatPicker
                       placeholder='Enter end date'
                       autoComplete='off'
                       displayFormat='ddd, D MMM YYYY'
                       type='text'
                       value={endDate}
                       onChange={setEndDate}
                       style={{flex: 1}}
            />
              <SelectInput
                  options={enums.INSTALLATION_TRANSACTIONS_SEARCH_OPTIONS}
                  value={installationsToQuery}
                  onChange={(e) => setInstallationToQuery(e.target.value)}
              />
            <Button spaceAbove="small" target={"_blank"} onClick={handleFetchCharges} style={{flex: 0.4, margin: 0}}>
              Select Charges
            </Button>
          </div>
          {(data && data.getChargesByDate) && (
            <div style={{display: 'flex', gap: '1rem', width: '100%'}}>
            <Input placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)}/>
            <SelectInput
              placeholder='Select approved status'
              options={approvedOptions}
              type='select'
              onChange={(e) => setApproved(e.target.value)}
              value={approved}
            />
          </div>)}
        </Container>
        <HR/>
        {(stripeCharges?.length > 0 || questCharges?.length > 0) && (
          <>
            <Container size='large' className={css({textAlign: "center", marginTop: '1rem'})}>
              <Button onClick={() => setTab('stripe')}>
                Stripe Charges
              </Button>
              <Button onClick={() => setTab('quest')} className={css({marginLeft: "1rem"})}>
                Quest Charges
              </Button>
              <Button spaceAbove="small" href={downloadLink} className={css({marginLeft: "1rem"})}>
                  Download
              </Button>
            </Container>
            {tab === 'stripe' &&
              <p style={{textAlign: 'center'}}>Approved: {stripeTotal.approved} (${stripeTotal.approvedTotal}) |
                Failed: {stripeTotal.failed} (${stripeTotal.failedTotal})</p>
            }
            {tab === 'quest' &&
              <p style={{textAlign: 'center'}}>Approved: {questTotal.approved} (${questTotal.approvedTotal})</p>
            }
            <Container size='large' spaceBelow='large'>
              {tab === 'stripe' && stripeTable(handleFilters(stripeCharges), appConfig, installation, true)}
              {tab === 'quest' && questTable(handleFilters(questCharges), true)}
            </Container>
          </>
        )}
        <div className={css({textAlign: "center"})}>
          {loading ? <Loading/> : null}
        </div>
      </CardContainer>
    </RootOnly>
  )
}

export default withInstallation(withAppConfig(Transactions))
