import React, { useEffect, useMemo, useState } from 'react';
import { Container, CardContainer } from '../../../components/Container';
import { H2, HR } from '../../../components/Typography';
import { Loading } from '../../../components/LoadingFork';
import { StatusPill } from '../../../components/StatusPill';
import { css } from 'glamor'
import { withAppConfig } from '../../../components/AppConfigContext';
import { withInstallation } from '../../../components/InstallationContext';
import gql from "graphql-tag";
import {useLazyQuery, useQuery} from "@apollo/client";
import drivoApollo from "../../../lib/drivoApollo";
import {fmtDate} from "../../../lib/misc";
import {Button} from "../../../components/Button";
import {questTable, stripeTable} from "./tables";
import {DateTime} from "luxon";
import {DateInput} from "../../../components/DateInput";

const statusFlavour = {
    PENDING: "warning",
    PROCESSED: "gray",
};

const statusStyles = css({
  position: 'absolute',
  top: 6,
  right: 16,
})

const payoutAndChargesQuery = gql`
    query payoutAndCharges($id: ID!, $startDay: String!, $endDay: String!) {
        getPayoutById(id: $id) {
            id
            createdAt
            finalisedAt
            amountAudRemit
        }
        getChargesByPayoutId(id: $id, startDay: $startDay, endDay: $endDay) {
            questCharges {
                hasMore
                charges {
                    id
                    createdAt
                    amount
                    plate 
                }
            }
            stripeCharges {
                stripeChargeId
                amountAud
                amountAudHotel
                amountAudService
                createdAt
                bookingId
                guestName
                guestEmail
                plate
                booking_start
                booking_end
            }
        }
    }
`;

const moreChargesQuery = gql`
    query payoutAndCharges($id: ID!, $startDay: String!, $endDay: String!) {
        getChargesByPayoutId(id: $id, startDay: $startDay, endDay: $endDay) {
            questCharges {
                hasMore
                charges {
                    id
                    createdAt
                    amount
                    plate
                }
            }
        }
    }
`;

function PayoutDetails({ match, installation, appConfig }) {
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(1);
  const [payout, setPayout] = useState({});
  const [tab, setTab] = useState('stripe');
  const [hasMore, setHasMore] = useState(false);
  const [stripeCharges, setStripeCharges] = useState([]);
  const [questCharges, setQuestCharges] = useState([]);
  const status = useMemo(() => payout?.finalisedAt ? "PROCESSED" : "PENDING", [payout])

  const fetchPayoutAndCharges = async () => {
    try {
      setLoading(true);
      const { data } = await drivoApollo.query({
        query: payoutAndChargesQuery,
        variables: { id: match.params.id, startDay: startDate, endDay: endDate },
        fetchPolicy: "no-cache",
      });

      if (data) {
        if (data.getPayoutById) setPayout(data.getPayoutById);
        if (data.getChargesByPayoutId) {
          setStripeCharges(data.getChargesByPayoutId.stripeCharges);
          setQuestCharges(data.getChargesByPayoutId.questCharges.charges);
          setHasMore(data.getChargesByPayoutId.questCharges.hasMore);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching charges:", error);
      return null;
    }
  };

  useEffect(() => fetchPayoutAndCharges(), []);

  const fetchMoreCharges = async (nextPage) => {
    try {
      setLoading(true);
      const { data } = await drivoApollo.query({
        query: moreChargesQuery,
        variables: { id: match.params.id, startDay: startDate, endDay: endDate, page: nextPage },
        fetchPolicy: "no-cache",
      });

      if (data?.getChargesByPayoutId?.questCharges) {
        setQuestCharges([...questCharges, ...data.getChargesByPayoutId.questCharges.charges]);
        setHasMore(data.getChargesByPayoutId.questCharges.hasMore);
      }
      setLoading(false);
      setPage(nextPage);
    } catch (error) {
      console.error("Error fetching charges:", error);
      return null;
    }
  };

  if (loading) return (
    <CardContainer>
      <Loading />
    </CardContainer>
  )

  return (
    <CardContainer>
      <Container size='large' spaceBelow='medium' >
        <StatusPill flavour={statusFlavour[status]} className={statusStyles}>{status}</StatusPill>
        <H2 spaceBelow='none'>{`Payout: ${ fmtDate(payout?.createdAt, installation.timeZoneName, "d MMM yyyy") }, $${ payout?.amountAudRemit }`}</H2>
        <div className={css({ marginTop: '1rem', display: 'flex', gap: '1.5rem' })}>
          <DateInput floatPicker
                     placeholder='Start date'
                     autoComplete='off'
                     displayFormat='ddd, D MMM YYYY'
                     type='text'
                     value={startDate}
                     onChange={setStartDate}
                     style={{flex: 2}}
          />
          <DateInput floatPicker
                     placeholder='Enter date'
                     autoComplete='off'
                     displayFormat='ddd, D MMM YYYY'
                     type='text'
                     value={endDate}
                     onChange={setEndDate}
                     style={{flex: 2}}
          />
          <Button style={{flex: 1}} onClick={fetchPayoutAndCharges}>
            Select Charges
          </Button>
        </div>
      </Container>
      <HR />
      <Container size='large' className={css({textAlign: "center", marginTop: '1rem'})}>
        <Button onClick={() => setTab('stripe')}>
          Stripe Charges
        </Button>
        <Button onClick={() => setTab('quest')} className={css({marginLeft: "1rem"})}>
          Quest Charges
        </Button>
      </Container>
      <Container size='large' spaceBelow='large'>
        { tab === 'stripe'
          ? stripeTable(stripeCharges, appConfig, installation)
          : questTable(questCharges, false, true) }
        <div className={css({textAlign: "center"})}>
          { tab === 'quest' && hasMore ? (
            <Button
              onClick={() => fetchMoreCharges(page + 1)} style={{backgroundColor: '#3127B8'}}
              spaceAbove="medium"
              spaceBelow="medium"
              disabled={chargesLoading}
            >
              Fetch More
            </Button>
          ) : null}
        </div>
      </Container>
    </CardContainer>
  )
}

export default withInstallation(withAppConfig(PayoutDetails))
