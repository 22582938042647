import {RootOnly} from "../../../components/AdminOnly";
import {Table, TBody, TD, THead, TR} from "../../../components/Table";
import {css} from "glamor";
import React from "react";
import {DateTime} from "luxon";

const urlStyles = css({
  cursor: "pointer",
  transition: "background 0.2s",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
});
const formattedDate = (date) => {
  if (!date) return '-';

  const dt = typeof date === 'number'
    ? DateTime.fromMillis(date).setZone('Australia/Sydney')
    : DateTime.fromISO(date, { zone: 'utc' }).setZone('Australia/Sydney');

  return dt.isValid ? dt.toFormat('d MMM yyyy') : '-';
};

export const questTable = (charges, renderApproved = false, isPayout = false) => {
  return charges?.length ? (
      <RootOnly>
        <Table spaceBelow='medium'>
          <THead>
            <tr>
              <th>Amount</th>
              <th>Plate</th>
              <th>{isPayout ? 'Created At' : 'Approved At'}</th>
              {renderApproved ? <th>Approved</th> : null}
            </tr>
          </THead>
          <TBody>
            {charges.map((charge, index) => (
              <TR className={urlStyles} key={`${index}-${charge.id}=${charge.updatedAt}`}>
                <TD>${(Number(charge.amount) / 100).toFixed(2)}</TD>
                <TD>{charge.plate}</TD>
                <TD>{formattedDate(Number(isPayout ? charge.createdAt : charge.GatewayCreatedAt))}</TD>
                {renderApproved ? <TD>{charge.approved ? '✅' : '❌'}</TD> : null}
              </TR>
            ))}
          </TBody>
        </Table>
      </RootOnly>
    ) :
    <div className={css({textAlign: "center", marginTop: '2rem'})}>
      No charges found.
    </div>;
}

const approvedValueWithRefunds = (charge) => {
  if (charge.requestedRefundAt && charge.processedRefundAt) return '🔁';
  return charge.approved === 'true' ? '✅' : '❌';
}

export const stripeTable = (charges, appConfig, installation, renderApproved = false) =>{
  return charges?.length ? (
      <RootOnly>
        <Table spaceBelow='medium'>
          <THead>
            <tr>
              <th>Total</th>
              <th>Hotel</th>
              <th>Service</th>
              <th>Created At</th>
              <th>Guest Name</th>
              <th>Guest Email</th>
              <th>Plate</th>
              <th>Start</th>
              <th>End</th>
              {renderApproved ? <th>Approved</th> : null}
            </tr>
          </THead>
          <TBody>
            {charges.map((charge, index) => (
              <TR
                className={urlStyles}
                key={`${index}-${charge.stripeChargeId || charge.bookingId}-${charge.createdAt}`}
                onClick={() => {
                  window.open(
                    `${appConfig.APPLICATION_URL}/${installation.brandingKey}/bookings/${charge.bookingId}`,
                    "_blank"
                  );
                }}
              >
                <TD>${Number(charge.amountAud).toFixed(2)}</TD>
                <TD>${Number(charge.amountAudHotel).toFixed(2)}</TD>
                <TD>${Number(charge.amountAudService).toFixed(2)}</TD>
                <TD>{formattedDate(charge.createdAt)}</TD>
                <TD>{charge.guestName}</TD>
                <TD>{charge.guestEmail}</TD>
                <TD>{charge.plate}</TD>
                <TD>{formattedDate(charge.booking_start)}</TD>
                <TD>{formattedDate(charge.booking_end)}</TD>
                {renderApproved ? <TD>{approvedValueWithRefunds(charge)}</TD> : null}
              </TR>
            ))}
          </TBody>
        </Table>
      </RootOnly>
    ) :
    <div className={css({textAlign: "center", marginTop: '2rem'})}>
      No charges found.
    </div>;
}
