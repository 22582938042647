import React, { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from "@apollo/client"
import { CardContainer, Container } from '../../components/Container';
import { Byline, H1, H2, H6 } from '../../components/Typography';
import { withInstallation } from '../../components/InstallationContext';
import { MediaPlayer } from 'dashjs';
import { getCameraStreams, getGates } from "./queries";
import drivoApollo from "../../lib/drivoApollo";
import { Button } from "../../components/Button";
import { cancelChargeV2, openGateMutation, restartQuestTerminal, updateSign } from "./mutations";

function Streams({ installation }) {
    const [showGateMessage, setShowGateMessage] = useState(false);
    const [terminalMessage, setTerminalMessage] = useState(null);
    const {
        data = {}
    } = useQuery(getCameraStreams, {
        client: drivoApollo,
        fetchPolicy: 'no-cache',
        variables: {
            id: installation.id
        },
    })

    const {
        data: gates = {}
    } = useQuery(getGates, {
        client: drivoApollo,
        fetchPolicy: 'no-cache',
        variables: {
            id: installation.id
        },
    })

    useLayoutEffect(() => {
        window.dash = {};
        return () => {
            console.log("unmounting", window.dash)
            for (let gateId of Object.keys(window.dash)) {
                let dashStream = window.dash[gateId];
                console.log("unmounted", gateId)
                dashStream.reset();
            }
        }
    }, [])

    const openGate = async (gateId) => {
        console.log("Sending open gate request to ", gateId)
        await drivoApollo.mutate({
            mutation: openGateMutation,
            variables: {
                id: gateId
            }
        });
        setShowGateMessage(true);
        setTimeout(() => setShowGateMessage(false), 3000)
    }

    const restartTerminal = async (gateId) => {
        try {
            console.log("Sending restart quest terminal request to ", gateId)
            await drivoApollo.mutate({
                mutation: restartQuestTerminal,
                variables: { gateId }
            });
            setTerminalMessage('Request was successful. Terminal will be restarted.')
        } catch (e) {
            setTerminalMessage(`Something went wrong. Error: ${e.message}`)
        }
        setTimeout(() => setTerminalMessage(null), 5000)
    }

    const resetSign = async (signId) => {
        try {
            console.info('Sending sign reset request to sign', signId);
            await drivoApollo.mutate({
                mutation: updateSign,
                variables: {
                    id: signId,
                    input: {
                        state: '{}'
                    }
                }
            })
        } catch (error) {
            setTerminalMessage(`Something went wrong. Error: ${error.message}`)
        }
    }

    const cancelCharge = async (gateId) => {
        try {
            console.info('cancelling charge for gate', gateId);
            await drivoApollo.mutate({
                mutation: cancelChargeV2,
                variables: {
                    id: gateId,
                }
            })
        } catch (error) {
            setTerminalMessage(`Something went wrong. Error: ${error.message}`)
        }
    }

    return <div>
        <Helmet>
            <title>Camera Streams</title>
        </Helmet>

        <CardContainer>
            <Container size="medium" spaceBelow="medium">
                <H1>Camera Streams</H1>
                <Byline>Your facility's live cameras</Byline>
            </Container>

            <Container size="medium" spaceBelow="large">
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    {
                        data?.StreamUrl?.map(u => {
                            console.log("CREATING VIDEO ", u.name);
                            return <div style={{ width: 320, padding: 10 }}>
                                <h3>{u.name}</h3>
                                <video
                                    style={{ width: '100%', height: 240 }}
                                    ref={(ref) => {
                                        const dashjs = MediaPlayer().create();
                                        window.dash[u.gateId] = dashjs;
                                        dashjs.initialize(ref, u.url, true);
                                    }}
                                    autoPlay={true}
                                />
                                {u.gateType === "EXIT" && u.signId != null &&
                                    <>
                                        <iframe title={`Signs-${u.signId}`} src={`https://drivo.io/signs/${u.signId}`} />
                                        <Button onClick={() => resetSign(u.signId)}>Reset</Button>
                                        <Button onClick={() => cancelCharge(u.gateId)}>Cancel Charge</Button>
                                    </>
                                }
                            </div>
                        })
                    }
                </div>
            </Container>
            <Container size="medium" spaceBelow="large">
                <H2>Gate Controls</H2>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    {
                        gates?.gatesForInstallation?.map(gate => {
                            return <Button onClick={() => openGate(gate.id)}>Open Gate {gate.gateDescription}</Button>
                        })
                    }
                </div>
                {showGateMessage && <H6>Gate open request sent.</H6>}
                <hr />
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    {
                        gates?.gatesForInstallation?.filter((g) => g.type === 'EXIT').map(gate => {
                            return <Button onClick={() => restartTerminal(gate.id)}>Restart Quest Terminal {gate.gateDescription}</Button>
                        })
                    }
                </div>
                {terminalMessage && <H6>{terminalMessage}</H6>}
            </Container>
        </CardContainer>
    </div>
}

export default withInstallation(Streams)
